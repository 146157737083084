import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import Chart from 'react-google-charts'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]

const DashboardWrapper = () => {
  const intl = useIntl()
  const data = [
    ['Month', 'Захиалга'],
    ['1 сар', 1000],
    ['2 сар', 1170],
    ['3 сар', 660],
    ['4 сар', 1030],
    ['5 сар', 1000],
    ['6 сар', 1170],
    ['7 сар', 660],
    ['8 сар', 1030],
    ['9 сар', 1000],
    ['10 сар', 1170],
    ['11 сар', 660],
    ['12 сар', 1030],
  ]

  const options = {
    title: 'Захиалгийн график',
    vAxis: { title: "Захиалгийн тоо" },
    hAxis: { title: "Сар" },
    seriesType: "bars", // Default series type (all bars)
    series: { 2: { type: "line" } }, // Use line chart for the third column
    colors: ["#4285F4", "#EA4335", "#FBBC05"], // Customize colors for series
    chartArea: { width: "70%", height: "70%" },
  }

  return (
    <>
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>
        {intl.formatMessage({id: 'MENU.DASHBOARD'})}
      </PageTitle>
      <div className='flex flex-col gap-8'>
        <div className='flex flex-col' style={{rowGap: 50}}>
          <div className='bot-border'>
            <h3>Захиалга</h3>
            <div
              className='card  gap-4 flex flex-col'
              style={{padding: '15px 25px', marginTop: '20px'}}
            >
              <Chart
                chartType='ComboChart'
                width='100%'
                height='400px'
                data={data}
                options={options}
                loader={<div>Loading Chart...</div>}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {DashboardWrapper}
